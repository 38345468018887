import React from "react";
import Broadcasting from "./View/Broadcasting/routes";

let routes = {};
routes["broadcasting"] = Broadcasting;
// const BroadcastingList = React.lazy(() => import("./View/Broadcasting/List"));

// const route = [{ path: "/broadcasting/list", exact: true, name: "BroadcastingList", component: BroadcastingList }];

export default routes;
