import React from "react";

const List = React.lazy(() => import("./list"));
const Info = React.lazy(() => import("./info"));

let routes = [];
routes = routes.concat({ path: "/list", exact: true, name: "basic", component: List });
// routes = routes.concat({ path: "/info", exact: true, name: "basic", component: Info });
routes = routes.concat({ path: "/info/:id", exact: true, name: "basic", component: Info });

export default routes;
