import React, { Component, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import windowSize from "react-window-size";

// import "../../node_modules/font-awesome/scss/font-awesome.scss";
import "../assets/style.scss";

import Loader from "./layout/Loader";
import Aux from "../hoc/_Aux";
import ScrollToTop from "./layout/ScrollToTop";
import routes from "../route";
import config from "../config";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = { show: false };
    }

    render() {
        // let menu = routes.map((route, index) => {
        //     return route.component ? <Route key={index} path={route.path} exact={route.exact} name={route.name} render={props => <route.component {...props} />} /> : null;
        // });

        let menu = [];
        let menuIdx = 0;
        for (const key in routes) {
            const subMenu = routes[key].map((route, index) => {
                menuIdx += 1;
                return route.component ? <Route key={menuIdx} path={"/" + key + route.path} exact={route.exact} name={route.name} render={props => <route.component {...props} />} /> : null;
            });

            menu = menu.concat(subMenu);
        }

        return (
            <Aux>
                <ScrollToTop>
                    <Suspense fallback={<Loader />}>
                        <div className="navigation">
                            {/* <svg width="86" height="28" viewBox="0 0 86 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.5 0.389305H15.8488V27.461H0.5V0.389305ZM20.4265 0H25.0641V28H20.4265V14.4342H16.9858V10.661H20.4265V0ZM5.16747 4.13262V23.7476H11.1813V4.13262H5.16747Z" fill="white" />
                                <path d="M49.7619 28V0H54.3994V28H49.7619ZM29.8353 0.389305H34.5028V23.7176H46.71V27.461H29.8353V0.389305Z" fill="white" />
                                <path d="M84.004 17.9979H60.0683V7.3369H78.7082V4.16257H60.0084V0.389305H83.4056V11.1102H64.7357V14.2545H84.004V17.9979ZM57.9739 23.6578H69.3733V19.2556H74.0408V23.6578H85.5V27.4011H57.9739V23.6578Z" fill="white" />
                            </svg> */}
                            {/* <svg width="80" height="30" viewBox="0 0 80 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_2728_145501)">
                                    <g filter="url(#filter0_d_2728_145501)">
                                        <path d="M0 23.4194V6.00018L14.889 15.0579L9.91923 18.0981L4.89013 15.0993L4.90957 20.7855L0 23.4194Z" fill="white" />
                                        <path d="M19.8384 23.4194V6.00018L4.89478 15.1012L9.91913 18.0981L14.9482 15.0993L14.9288 20.7855L19.8384 23.4194Z" fill="#FAAF40" />
                                    </g>
                                    <path
                                        d="M32.7926 16.7227C32.7926 16.943 32.7836 17.1392 32.6909 17.22C32.5982 17.3007 32.4821 17.3862 32.2682 17.3862H29.4465C29.2325 17.3862 29.007 17.3007 28.9143 17.22C28.8209 17.1392 28.7023 16.943 28.7023 16.7227V9.27519H30.962H32.7926V16.7227ZM38.3979 11.927H35.8225V6.77957H30.962H25.6731V17.4496C25.6731 18.2425 25.9661 18.9493 26.4153 19.3385C26.8645 19.7276 27.6424 20.038 28.6122 20.038H33.1025C34.0729 20.038 34.7406 19.7276 35.1898 19.3385C35.639 18.9493 35.8225 18.2425 35.8225 17.4496V14.4226H38.3979V23.7817H41.4271V5.99998H38.3979V11.927Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M57.3189 15.3605C56.8589 15.7172 56.4403 16.0186 55.8628 16.2638C55.2854 16.5091 54.7591 16.7131 54.1817 16.8766C53.5898 17.0253 53.0491 17.2388 52.5077 17.3131C51.9664 17.3874 51.5267 17.5259 51.1363 17.5259H49.5989C49.2091 17.5259 48.883 17.3462 48.7963 17.1901C48.7097 17.0341 48.5785 16.7131 48.5785 16.4307V6.78987H45.5115V17.2334C45.5115 18.1556 45.8225 18.9103 46.3639 19.334C46.9052 19.7583 47.6933 20.0522 48.6461 20.0522H51.2013C52.2118 20.0522 53.1882 19.8285 54.3359 19.5461C55.4835 19.2637 56.3989 18.7874 57.3189 18.2813V24H60.5388V6.00004H57.3189V15.3605Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M73.2033 19.2378V16.798H78.0792V14.5108H69.0019C68.8376 14.5108 68.6782 14.3718 68.615 14.2608C68.5518 14.1499 68.4753 13.9326 68.4753 13.7753V12.8331H77.7833V6.58097H65.816V8.86816H75.124V10.5459H65.816V14.3052C65.816 14.6713 65.9393 14.9982 66.0784 15.286C66.2181 15.5738 66.4407 15.8152 66.6949 16.0116C66.9478 16.2074 67.2557 16.4469 67.592 16.5519C67.9271 16.6564 68.3115 16.798 68.7168 16.798H70.544V19.2378H63.5994V21.6777H80V19.2378H73.2033Z"
                                        fill="white"
                                    />
                                </g>

                                <defs>
                                    <filter id="filter0_d_2728_145501" x="-1" y="6" width="27.8384" height="25.4194" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                        <feOffset dx="3" dy="4" />
                                        <feGaussianBlur stdDeviation="2" />
                                        <feComposite in2="hardAlpha" operator="out" />
                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2728_145501" />
                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2728_145501" result="shape" />
                                    </filter>
                                    <clipPath id="clip0_2728_145501">
                                        <rect width="80" height="30" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg> */}
                            <svg width="91" height="30" viewBox="0 0 91 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.1686 3.27411V12.1066H17.0058H14.9651H3.77905V24.8985C3.77905 26.5736 5.13952 27.868 6.72673 27.868H19.2732V16.1421H25.1686V27.868H29.4767V3.27411H25.1686ZM15.0407 23.7563H8.16277V16.1421H15.0407V23.7563Z" fill="white"/>
<path d="M60.7675 27.868H87.5233V23.6802H76.2616V20.2538H82.6105V16.2183H69.9884V13.6294H82.2326V5.40609C82.2326 5.10152 82.157 4.79695 82.0814 4.49238C81.9302 4.03553 81.6279 3.65482 81.25 3.42639C80.8721 3.19797 80.4186 3.12183 79.8895 3.12183H65.6802V7.15736H77.9244V9.59391H65.6802V17.9695C65.6802 18.2741 65.7558 18.5787 65.8314 18.8832C65.9826 19.3401 66.2849 19.7208 66.6628 19.9492C67.0407 20.1777 67.4942 20.2538 68.0233 20.2538H71.9535V23.6802H60.7675V27.868Z" fill="white"/>
<path d="M57.593 3.27411H53.436V27.9442H57.593V3.27411Z" fill="white"/>
<path d="M39.529 22.7665C38.9244 22.1574 38.6221 21.3959 38.6221 20.5584V12.1827H34.4651V20.5584C34.4651 22.6142 35.2965 24.4416 36.5814 25.736C37.8663 27.0305 39.7558 27.868 41.7209 27.868H51.093V23.6802H41.7209C40.8895 23.6802 40.1337 23.3756 39.529 22.7665Z" fill="white"/>
</svg>

                        </div>
                        <Switch>
                            {menu}
                            <Redirect from="/" to={config.defaultPath} />
                        </Switch>
                    </Suspense>
                </ScrollToTop>
            </Aux>
        );
    }
}

// const mapStateToProps = state => {
//     return {
//         defaultPath: state.defaultPath
//     };
// };

// export default connect(mapStateToProps, null)(windowSize(App));

export default App;
