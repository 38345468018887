import React from "react";

const Aux = props => props.children;

export default Aux;

export const addComma = number => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const addSortEvent = (parent, title, id) => {
    if (parent.state.data.sort === id) {
        return (
            <a
                href="javascript:;"
                onClick={e => {
                    const { data } = parent.state;
                    data.sort = "-" + id;
                    parent.setState({ data }, () => {
                        parent.reloadList(1);
                    });
                }}
            >
                {title + "▲"}
            </a>
        );
    } else if (parent.state.data.sort === "-" + id) {
        return (
            <a
                href="javascript:;"
                onClick={e => {
                    const { data } = parent.state;
                    data.sort = id;
                    parent.setState({ data }, () => {
                        parent.reloadList(1);
                    });
                }}
            >
                {title + "▼"}
            </a>
        );
    }
    return (
        <a
            href="javascript:;"
            onClick={e => {
                const { data } = parent.state;
                data.sort = id;
                parent.setState({ data }, () => {
                    parent.reloadList(1);
                });
            }}
        >
            {title}
        </a>
    );
};
